import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppContext from '../../AppContext';
import InfoCard from '../../components/InfoCard';
import ResourceTitleBar from '../../components/ResourceTitleBar';
import SimpleResourceItem from '../../components/SimpleResourceItem';
import BottomUpSheet from '../../components/BottomUpSheet';
import {Settings} from '@styled-icons/material';
import CollectionDetail from './CollectionDetail';

function TabCollection(props) {
  const {collections, fetchCollections, onItemClick} = props;
  const bottomSheetRef = React.useRef();
  const app = React.useContext(AppContext.Context);

  function showEditCollectionBottomSheet(collection) {
    bottomSheetRef.current.open(
      collection?.name || 'Create Collection',
      <CollectionDetail
        key={collection?.name || Math.random()}
        collection={collection}
        onClose={() => bottomSheetRef.current.close()}
        onUpdate={fetchCollections}
      />,
    );
  }

  return (
    <Wrapper>
      <InfoCard style={{marginTop: 20}}>
        <ResourceTitleBar
          title="Collections"
          onCreateClick={() => {
            showEditCollectionBottomSheet(null);
          }}
        />

        {collections.map((collection, idx) => (
          <SimpleResourceItem
            key={idx}
            resource={collection}
            idx={idx}
            onClick={() => onItemClick(collection)}
            actions={[
              {
                icon: <Settings size={24} color="black" />,
                onClick: () => showEditCollectionBottomSheet(collection),
              },
            ]}
          />
        ))}
      </InfoCard>

      <BottomUpSheet
        getInstance={(inst) => {
          bottomSheetRef.current = inst;
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default TabCollection;
