function format(date) {
  try {
    if (isNaN(date)) {
      throw new Error();
    }
    let value = date;

    // timestamp digit < 13, not millisecond
    if (date.toString().length < 13) {
      value = date * 1000;
    }

    // timezone offset;
    const offset = new Date().getTimezoneOffset() * 60000;
    const str = new Date(value - offset).toISOString();

    // formating YYYY-MM-DD HH:mm
    return str.slice(0, 16).replace('T', ' ');
  } catch (error) {
    return '-';
  }
}

export default {
  format,
};
