import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppContext from '../../AppContext';
import InfoCard from '../../components/InfoCard';
import ResourceTitleBar from '../../components/ResourceTitleBar';
import {ArrowForward} from '@styled-icons/material';
import BottomUpSheet from '../../components/BottomUpSheet';
import Detail from './PermissionDetail';

export default function TabPermission(props) {
  const {collections = [], selected} = props;
  const [collectionName, setCollectionName] = React.useState(
    selected?.name || collections[0]?.name || '',
  );
  const [records, setRecords] = React.useState([]);
  const bottomSheetRef = React.useRef();
  const app = React.useContext(AppContext.Context);
  const collection = collections.find((c) => c.name === collectionName);

  const getRecords = React.useCallback(async () => {
    app.actions.setLoading(true);
    try {
      setRecords(await app.actions.fetchPermissions(collection.name));
    } catch (ex) {
      app.actions.onApiError(ex);
    }
    app.actions.setLoading(false);
  }, [app.actions, collection]);

  React.useEffect(() => {
    if (collection) {
      getRecords();
    }
  }, [collection, getRecords]);

  function showEditModal(record) {
    bottomSheetRef.current.open(
      record ? '編輯' : '新增',
      <Detail
        key={Math.random()}
        collection={collection}
        record={record}
        onClose={() => bottomSheetRef.current.close()}
        onUpdate={getRecords}
      />,
    );
  }

  const selectStyle = {width: 150};

  return (
    <Wrapper>
      <Ant.Select
        placeholder="Select collection"
        value={collectionName}
        onChange={setCollectionName}
        style={selectStyle}>
        {collections.map((c) => (
          <Ant.Select.Option key={c.name} style={selectStyle} value={c.name}>
            {c.name}
          </Ant.Select.Option>
        ))}
      </Ant.Select>

      {collection && (
        <InfoCard style={{marginTop: 20}}>
          <ResourceTitleBar
            title={collection.name}
            onCreateClick={() => {
              showEditModal(null);
            }}
            onRefreshClick={getRecords}
          />
          <Ant.Table
            columns={[
              {
                title: 'rrn',
                dataIndex: 'rrn',
              },
              {
                title: 'principal',
                dataIndex: 'principal',
              },
              {
                title: 'acl',
                key: 'acl',
                render: (record) => (
                  <div style={{display: 'flex'}}>
                    {record.acl.map((x, idx) => (
                      <Ant.Tag key={idx}>{x}</Ant.Tag>
                    ))}
                  </div>
                ),
              },
              {
                title: '',
                key: 'actions',
                render: (record) => (
                  <Ant.Button type="text" onClick={() => showEditModal(record)}>
                    <ArrowForward size={24} color="black" />
                  </Ant.Button>
                ),
              },
            ]}
            dataSource={records}
          />
        </InfoCard>
      )}

      <BottomUpSheet
        getInstance={(inst) => {
          bottomSheetRef.current = inst;
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 80px;
`;
