import React, {useContext} from 'react';
import * as Ant from 'antd';
import InputField from '../../components/InputField';
import {Context} from '../../AppContext';
import {date} from '../../utils';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
const constants = require('./data.json');

export default function DocumentDetail(props) {
  const {collection, document, onClose, onUpdate} = props;
  const {id, created, updated, ...editableContent} = document || {};
  const [values, setValues] = React.useState(() => {
    for (let field in constants.reservedFields) {
      delete editableContent[field];
    }

    let json = editableContent;
    if (collection.primary_key && collection.primary_key !== 'id') {
      json = {
        [collection.primary_key]: '',
        ...editableContent,
      };
    }
    return JSON.stringify(json, null, 2);
  });

  const app = useContext(Context);
  const primaryKey = collection?.primary_key || 'id';

  const putRecord = async (record) => {
    app.actions.setLoading(true);
    try {
      if (record[primaryKey]) {
        return alert('Primary key 不能編輯');
      }

      let invalid = Object.keys(record).reduce(
        (s, key) => s || !!constants.reservedFields[key],
        false,
      );
      if (invalid) {
        return alert(
          `保留欄位${Object.keys(constants.reservedFields).join(
            ', ',
          )} 不能編輯`,
        );
      }

      if (document) {
        // edit
        await JStorage.updateDocument(
          collection.name,
          {
            [primaryKey]: document[primaryKey],
          },
          record,
        );
      } else {
        await JStorage.createDocument(collection.name, record);
      }

      onUpdate();
      onClose();
    } catch (ex) {
      app.actions.onApiError(ex);
    }
    app.actions.setLoading(false);
  };

  return (
    <div>
      {id && <InputField label="ID" value={id} disabled row />}

      <Ant.Input.TextArea
        value={values}
        onChange={(e) => setValues(e.target.value)}
        style={{height: 250}}
      />

      <div style={{height: 32}} />

      {created && (
        <InputField
          style={{maxWidth: 250}}
          label="CREATED"
          // value={new Date(created * 1000).toISOString().split('.')[0]}
          value={date.format(created)}
          disabled
          row
        />
      )}

      {updated && (
        <InputField
          style={{maxWidth: 250}}
          label="UPDATED"
          // value={new Date(updated * 1000).toISOString().split('.')[0]}
          value={date.format(updated)}
          disabled
          row
        />
      )}

      <Ant.Button
        type="primary"
        style={{marginTop: 32}}
        onClick={() => {
          try {
            const json = JSON.parse(values);
            putRecord(json);
          } catch (ex) {
            alert('JSON format error!');
          }
        }}>
        CONFIRM
      </Ant.Button>
    </div>
  );
}
