import React from 'react';
import * as Ant from 'antd';
import InputField from '../../components/InputField';
import {useOutlet} from 'reconnect.js';

export default function Detail(props) {
  const [user] = useOutlet('user');
  const [actions] = useOutlet('actions');
  const {collection, record, onClose, onUpdate} = props;
  // const [values, setValues] = React.useState(() => {
  //   let json = editableContent;
  //   return JSON.stringify(json, null, 2);
  // });

  const [values, setValues] = React.useState(() => {
    if (record) {
      //edit
      let {id, created, updated, ...editableContent} = record || {};
      const lastColonIdx = record.principal.lastIndexOf(':');
      let principal = record.principal.slice(lastColonIdx + 1);
      let principal_prefix = record.principal.slice(0, lastColonIdx + 1);
      editableContent = {
        ...editableContent,
        principal,
        principal_prefix,
      };
      return editableContent;
    } else {
      //add
      return {
        principal_prefix: `${user?.client}:${collection.name}:`,
        principal: `*`,
        acl: [],
        rrn: '*',
      };
    }
  });

  const getValue = (field) => values[field];
  const onValueChange = (field) => (e) => {
    setValues({...values, [field]: e.target.value});
  };
  const onValueSelect = (field) => (value) => {
    setValues({...values, [field]: value});
  };

  const putRecord = async (record) => {
    actions.setLoading(true);
    try {
      await actions.putPermission(collection.name, record);

      onUpdate();
      onClose();
    } catch (ex) {
      actions.onApiError(ex);
    }
    actions.setLoading(false);
  };

  return (
    <div>
      <InputField label="acl" style={{maxWidth: 300}}>
        <Ant.Select
          mode="multiple"
          allowClear
          style={{width: '100%'}}
          placeholder="Please select"
          value={getValue('acl')}
          onChange={onValueSelect('acl')}>
          <Ant.Select.Option value="get_object">get_object</Ant.Select.Option>
          <Ant.Select.Option value="query">query</Ant.Select.Option>
          <Ant.Select.Option value="create">create</Ant.Select.Option>
          <Ant.Select.Option value="update">update</Ant.Select.Option>
          <Ant.Select.Option value="delete">delete</Ant.Select.Option>
          <Ant.Select.Option value="bulk_write">bulk_write</Ant.Select.Option>
        </Ant.Select>
      </InputField>

      <div style={{padding: 15, color: '#aaa'}}>
        <div>
          <span style={{color: '#E50914'}}>query</span> : find available (list)
        </div>
        <div>
          <span style={{color: '#E50914'}}>get_object</span> : find-one
          available (one item)
        </div>
        <div>
          <span style={{color: '#E50914'}}>create</span> : create available
        </div>
        <div>
          <span style={{color: '#E50914'}}>update</span> : update available
        </div>
        <div>
          <span style={{color: '#E50914'}}>delete</span> : delete available
        </div>
        <div>
          <span style={{color: '#E50914'}}>bulk_write</span> : bulk_write
          available
        </div>
      </div>

      <InputField
        style={{maxWidth: 260}}
        label="principal"
        value={getValue('principal')}
        onChange={onValueChange('principal')}
        disabled={!!record}
        addonBefore={getValue('principal_prefix')}
      />

      <div style={{padding: 15, color: '#aaa'}}>
        <div>format: [client]:[collection]:*</div>
        <div>
          <span style={{color: '#E50914'}}>*</span> : for all documents
        </div>
        <div>
          <span style={{color: '#E50914'}}>id</span> : for specific document
        </div>
        <div>eg. [client]:[collection]:et2234-2342-1312</div>
      </div>

      <InputField
        style={{maxWidth: 200}}
        label="rrn (revtel resource name)"
        value={getValue('rrn')}
        onChange={onValueChange('rrn')}
        placeholder="* or @ or someone"
        disabled={!!record}
      />

      <div style={{padding: 15, color: '#aaa'}}>
        <div>
          <span style={{color: '#E50914'}}>*</span> : all user
        </div>
        <div>
          <span style={{color: '#E50914'}}>@</span> : login user
        </div>
        <div>
          <span style={{color: '#E50914'}}>user:id</span> : specific user{' '}
        </div>
        <div>eg. user:ef123-345-3234</div>
      </div>

      {/* <Ant.Input.TextArea
        value={values}
        onChange={(e) => setValues(e.target.value)}
        style={{height: 250}}
      /> */}
      <Ant.Button
        type="primary"
        style={{marginTop: 32}}
        onClick={() => {
          try {
            // const json = JSON.parse(values);
            // putRecord(json);

            let data = {...values};
            delete data.principal_prefix;

            putRecord(data);
          } catch (ex) {
            alert('JSON format error!');
          }
        }}>
        CONFIRM
      </Ant.Button>
    </div>
  );
}
