import React, {useContext} from 'react';
import * as Ant from 'antd';
import InputField from '../../components/InputField';
import {Context} from '../../AppContext';
import {useOutlet} from 'reconnect.js';
import {Switch} from 'antd';

export default function CollectionDetail(props) {
  const {collection, onClose, onUpdate} = props;
  const [values, setValues] = React.useState(
    {
      ...collection,
      enable_cache_bucket: !!collection?.cache_bucket,
    } || {
      name: '',
      primary_key: '',
      actions: [],
      enable_cache_bucket: false,
    },
  );

  const app = useContext(Context);
  const [user] = useOutlet('user');

  const getValue = (field) => values[field];
  const onValueChange = (field) => (e) => {
    setValues({...values, [field]: e.target.value});
  };
  const onValueSelect = (field) => (value) => {
    setValues({...values, [field]: value});
  };

  const putRecord = async (record) => {
    app.actions.setLoading(true);
    try {
      const isStaging = user.env === 'stg';
      let payload = {
        ...record,
      };
      if (values.enable_cache_bucket) {
        payload = {
          ...payload,
          cache_bucket: isStaging
            ? `${user.aud}-revtel2-com-stg`
            : `${user.aud}-revtel2-com-prod`,
        };
      } else {
        delete payload.cache_bucket;
      }
      delete payload.enable_cache_bucket;

      await app.actions.putCollection(payload);

      onUpdate();
      onClose();
    } catch (ex) {
      app.actions.onApiError(ex);
    }
    app.actions.setLoading(false);
  };

  return (
    <div>
      <InputField
        label="NAME"
        value={getValue('name')}
        onChange={onValueChange('name')}
        style={{maxWidth: 300}}
        disabled={!!collection}
      />

      <InputField
        label="PRIMAY KEY"
        value={getValue('primary_key')}
        onChange={onValueChange('primary_key')}
        style={{maxWidth: 300}}
      />

      <InputField
        label="OWNER FIELD"
        value={getValue('owner_field')}
        onChange={onValueChange('owner_field')}
        style={{maxWidth: 300}}
        defaultValue="owner"
      />

      <InputField label="ACTIONS" style={{maxWidth: 300}}>
        <Ant.Select
          mode="multiple"
          allowClear
          style={{width: '100%'}}
          placeholder="Please select"
          value={getValue('actions')}
          onChange={onValueSelect('actions')}>
          <Ant.Select.Option value="owner_read">OWNER READ</Ant.Select.Option>
          <Ant.Select.Option value="owner_write">OWNER WRITE</Ant.Select.Option>
        </Ant.Select>
      </InputField>

      <InputField label="SUPERVISOR" style={{maxWidth: 300}}>
        <Ant.Select
          mode="multiple"
          allowClear
          style={{width: '100%'}}
          placeholder="Please select"
          value={getValue('supervisors')}
          onChange={onValueSelect('supervisors')}>
          <Ant.Select.Option value="admin">admin</Ant.Select.Option>
          <Ant.Select.Option value="management">management</Ant.Select.Option>
        </Ant.Select>
      </InputField>

      <InputField label="CACHE BUCKET" style={{maxWidth: 300}}>
        <Switch
          style={{maxWidth: 80}}
          size="default"
          checkedChildren="開啟"
          unCheckedChildren="關閉"
          onChange={(checked) => {
            setValues((prev) => ({
              ...prev,
              enable_cache_bucket: checked,
            }));
          }}
          checked={values.enable_cache_bucket}
        />
      </InputField>

      {collection && (
        <InputField
          style={{maxWidth: 200}}
          label="CREATED"
          value={
            collection.created
              ? new Date(collection.created).toISOString().split('.')[0]
              : '-'
          }
          disabled
        />
      )}

      {collection && (
        <InputField
          style={{maxWidth: 200}}
          label="UPDATED"
          value={
            collection.updated
              ? new Date(collection.updated).toISOString().split('.')[0]
              : '-'
          }
          disabled
        />
      )}

      <Ant.Button
        type="primary"
        style={{marginTop: 32}}
        onClick={() => putRecord(values)}>
        CONFIRM
      </Ant.Button>
    </div>
  );
}
