import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import TabBar from '../../components/TabBar';
import TabCollection from './TabCollection';
import TabDocument from './TabDocument';
import TabPermission from './TabPermission';

const TabItems = ['COLLECTION', 'DOCUMENT', 'PERMISSION'];

function JsonStoreServicePage(props) {
  const [selectedTab, setSelectedTab] = React.useState(TabItems[0]);
  const [collections, setCollections] = React.useState([]);
  const [selectedCol, setSelectedCol] = React.useState();
  const app = React.useContext(AppContext.Context);
  const fetchCollections = React.useCallback(async () => {
    app.actions.setLoading(true);
    try {
      setCollections(await app.actions.fetchCollections());
    } catch (ex) {
      app.actions.onApiError(ex);
    }
    app.actions.setLoading(false);
  }, [app.actions]);

  React.useEffect(() => {
    fetchCollections();
  }, [fetchCollections]);

  return (
    <Outer>
      <Wrapper>
        <TabBar
          items={TabItems}
          onItemClick={setSelectedTab}
          isItemActive={(item) => item === selectedTab}
        />
        <div className="tab-content">
          {selectedTab === TabItems[0] && (
            <TabCollection
              collections={collections}
              fetchCollections={fetchCollections}
              onItemClick={(col) => {
                setSelectedCol(col);
                setSelectedTab(TabItems[1]);
              }}
            />
          )}
          {selectedTab === TabItems[1] && (
            <TabDocument collections={collections} selected={selectedCol} />
          )}
          {selectedTab === TabItems[2] && (
            <TabPermission collections={collections} selected={selectedCol} />
          )}
        </div>
      </Wrapper>
    </Outer>
  );
}

const Outer = styled.div`
  padding: 80px 20px 0px 20px;
  background-color: ${AppContext.Theme.lightBgColor};
  height: 100vh;
  position: relative;
`;

const Wrapper = styled.div`
  height: 100%;
  max-width: 1024px;
  margin: 0 auto;

  & > .tab-content {
    height: calc(100% - 40px);
    overflow: auto;
  }
`;

export default JsonStoreServicePage;
