import React, {useState} from 'react';
import styled from 'styled-components';
import {Select, Pagination, Input} from 'antd';
import * as AppContext from '../../AppContext';
import InfoCard from '../../components/InfoCard';
import ResourceTitleBar from '../../components/ResourceTitleBar';
import SimpleResourceItem from '../../components/SimpleResourceItem';
import BottomUpSheet from '../../components/BottomUpSheet';
import DocumentDetail from './DocumentDetail';
import {SortAscendingOutlined} from '@ant-design/icons';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';

const INIT_PAGING = {
  limit: 20,
  offset: 0,
};

function TabDocument(props) {
  const {collections = [], selected} = props;
  const [collectionName, setCollectionName] = React.useState(
    selected?.name || collections[0]?.name || '',
  );
  const [filters, setFilters] = useState({
    search: '',
    sorting: 'id',
    ...INIT_PAGING,
  });
  const [total, setTotal] = React.useState(0);
  const [documents, setDocuments] = React.useState([]);
  const bottomSheetRef = React.useRef();
  const app = React.useContext(AppContext.Context);
  const collection = collections.find((c) => c.name === collectionName);
  const primaryKey = collection?.primary_key || 'id';

  const fetchDocuments = React.useCallback(async () => {
    app.actions.setLoading(true);
    try {
      let {search, limit, offset, sorting} = filters;
      let resp = await JStorage.fetchDocuments(
        collection.name,
        search
          ? {
              [primaryKey]: search,
            }
          : {},
        [sorting],
        {
          offset: limit * offset,
          limit,
        },
        {password: 0},
      );

      setDocuments(resp.results);
      setTotal(resp.total);
    } catch (ex) {
      console.warn('fetchDocuments error', ex);

      app.actions.onApiError(ex);
    }
    app.actions.setLoading(false);
  }, [app.actions, collection, filters, primaryKey]);

  React.useEffect(() => {
    if (collection) {
      fetchDocuments();
    }
  }, [collection, fetchDocuments]);

  function showEditDocumentBottomSheet(document) {
    bottomSheetRef.current.open(
      (document && `[${collection.name}] ${document[primaryKey]}`) ||
        `Create ${collection.name}`,
      <DocumentDetail
        key={Math.random()}
        collection={collection}
        document={document}
        onClose={() => bottomSheetRef.current.close()}
        onUpdate={fetchDocuments}
      />,
    );
  }

  async function confirmBeforeDelete(collectionName, doc) {
    const confirmResp = prompt('Type YES to confirm delete', 'No');
    if (confirmResp && confirmResp.toLocaleLowerCase() === 'yes') {
      app.actions.setLoading(true);
      try {
        await JStorage.deleteDocument(collectionName, {
          [primaryKey]: doc[primaryKey],
        });
        await fetchDocuments();
      } catch (ex) {
        app.actions.onApiError(ex);
      }
      app.actions.setLoading(false);
    }
  }

  function filtersChange(values) {
    setFilters((prev) => ({...prev, ...values}));
  }

  const selectStyle = {width: 150};

  return (
    <Wrapper>
      <div style={{display: 'flex'}}>
        <Select
          placeholder="Select collection"
          value={collectionName}
          onChange={(value) => {
            setCollectionName(value);
            filtersChange({
              search: '',
              ...INIT_PAGING,
            });
          }}
          style={selectStyle}>
          {collections.map((c) => (
            <Select.Option key={c.name} style={selectStyle} value={c.name}>
              {c.name}
            </Select.Option>
          ))}
        </Select>
        <div style={{flex: 1}} />
        <Input.Search
          placeholder="搜尋PK"
          allowClear
          onSearch={(search) => filtersChange({search, ...INIT_PAGING})}
          style={{width: 200, marginRight: 10}}
        />
        <Select
          value={filters.sorting}
          onChange={(sorting) => filtersChange({sorting, ...INIT_PAGING})}
          suffixIcon={<SortAscendingOutlined style={{color: '#aaa'}} />}>
          <Select.Option key="id" value="id">
            系統編號
          </Select.Option>
          {/* <Select.Option key="-created" value="-created">
            最後新增
          </Select.Option> */}
          <Select.Option key="-updated" value="-updated">
            最後更新
          </Select.Option>
        </Select>
      </div>
      {(collection && (
        <InfoCard style={{marginTop: 20}}>
          <ResourceTitleBar
            title={collection.name}
            onCreateClick={() => {
              showEditDocumentBottomSheet(null);
            }}
            onRefreshClick={fetchDocuments}
          />
          {documents.map((document) => (
            <SimpleResourceItem
              key={document.id}
              onClick={() => showEditDocumentBottomSheet(document)}
              onDelete={() => confirmBeforeDelete(collection.name, document)}>
              <div>
                {document[primaryKey] || '---'}
                {(() => {
                  let {children, content, password, updated, created, ...meta} =
                    document;
                  return (
                    <div style={{color: '#aaa', fontSize: 12, paddingLeft: 10}}>
                      {JSON.stringify(meta).slice(1, 100)}
                    </div>
                  );
                })()}
              </div>
            </SimpleResourceItem>
          ))}
        </InfoCard>
      )) || (
        <InfoCard style={{marginTop: 20}}>
          <ResourceTitleBar title="No Collection Selected" />
        </InfoCard>
      )}
      <Pagination
        total={total}
        current={filters.offset + 1}
        pageSize={filters.limit}
        showSizeChanger={false}
        showQuickJumper
        showTotal={(total) => '共 ' + total + ' 筆'}
        onChange={(page, pageSize) => {
          filtersChange({
            offset: page - 1,
            limit: pageSize,
          });
        }}
        style={{marginTop: 20}}
      />

      <BottomUpSheet
        getInstance={(inst) => {
          bottomSheetRef.current = inst;
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 80px;
`;

export default TabDocument;
